.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.landing-page {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.landing-page-content {
  width: 100%;
  height: 100%;
  background: #7AD1ED;
  background-image: url('../public/boat-full.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: -50px;
  background-size: 100%;
  background-repeat: no-repeat;
}

.landing-page-cta {
  flex: 1;
  position: absolute;
  top: 80px;
  left: 2%;
  width: 35%;
  min-width: 315px;
  max-width: 400px;
  border-radius: 16px;
  padding: 24px;
  background: white;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  padding-bottom: 18px;
}

.landing-page-cta-text {
  color: #000000ba;
  font-size: 22px;
  line-height: 1.5;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  font-weight: 600;
  font-family: 'Roboto', sans-serif;
}

.landing-page-cta-phone {
  width: 100%;
  max-width: 375px;
  margin: 0 auto;
  padding-top: 24px;
  border-bottom: 1px solid black;
}

.landing-page-cta-button {
  margin-top: 24px !important;
  max-width: 250px;
}

.landing-page-footer {
  margin-top: 36px;
  font-size: 12px;
  text-align: right;
  color: #5f5f5f;
}

/* Make all divs have margin-bottom except the last one */
.landing-page-footer div {
  margin-bottom: 4px;
}

.landing-page-footer div:last-of-type {
  margin-bottom: 0;
}

.landing-page-footer a {
  color: #233f9c;
}

@media (max-width: 600px) {
  .landing-page {
    max-width: 600px;
  }

  .landing-page-content {
    background-image: url('../public/boat.png') !important;
    background-position-x: center !important;
    background-position-y: -120px !important;
    background-repeat: no-repeat !important;
    width: 100%;
    height: 100%;
    background: #7AD1ED;
  }

  .landing-page-cta {
    top: unset !important;
    bottom: 5% !important;
    /* top: 20% !important; */
    min-width: unset !important;
    width: 90% !important;
    padding: 16px !important;
    box-sizing: border-box !important;
    left: 50% !important;
    transform: translateX(-50%);
    padding-bottom: 24px !important;
  }

  .landing-page-cta-text {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
    margin: 0 auto;
    width: 100%;
    padding: 8px 8px 0 8px;
    box-sizing: border-box;
    font-weight: 600;
    font-family: 'Roboto', sans-serif;
  }

  .landing-page-cta-button {
    width: 100% !important;
    margin-top: 0px !important;
  }

  .landing-page-cta-phone {
    transform: scale(0.8);
    padding-top: 8px !important;
  }
}


.route {
  padding: 16px;
}

.route-picker-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('../public/boat-full.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
}

.route-picker-scroll-container {
  overflow-y: auto;
  height: 100%;

}

.route-picker {
  padding: 24px;
  max-width: 430px;
  margin: 0 auto;
  background-color: white;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  border-radius: 8px;
  margin-top: 50px;
}

@media (max-width: 600px) {
  .route-picker {
    margin-top: 52px;
    margin-left: 16px;
    margin-right: 16px;
  }

  .route-picker-row {
    padding-bottom: 28px !important;
  }
}


.route-picker-selections {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
  align-items: center;
  max-width: 415px;
  margin: 0 auto;
}

.route-picker-requested-routes {
  padding-bottom: 24px;
}

.route-picker h3 {
  margin-top: 8px;
}

.route-picker-row {
  padding-bottom: 16px;
  width: 415px;
}

.route-picker-row .react-datepicker-wrapper {
  width: 100%;
}

.route-picker-times-container {
  max-width: 415px;
  padding: 0px 0 40px 0;
  margin: 0px auto 16px auto;
}

.route-picker-times-heading {
  margin-bottom: 0px;
  margin-top: 8px;
  font-size: 16px;
}


.route-picker-time {
  width: 120px;
  text-align: left;
  padding: 0px 8px;
}

.route-picker-times-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  max-width: 300px;
  margin: 16px auto;
}

.route-picker-loading {
  margin-top: 24px;
}


.route-picker-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #3f50b5;
  padding-top: 16px;
  padding-bottom: 16px;
}

.route-picker-footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 415px;
  padding: 0 16px;
  margin: 0 auto;
}

.route-table {
  margin: 0 auto;
}

.react-datepicker__header {
  background: white !important;
}

.react-datepicker__current-month {
  font-size: 1rem !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.react-datepicker__day-name {
  width: 2.5rem !important;
}

.react-datepicker__day {
  width: 2.5rem !important;
  font-size: 1.2rem !important;
  line-height: 2.5rem !important;
}

.checkout-phone-number {
  max-width: 400px;
  width: 100%;
  margin-top: 24px !important;
  margin-bottom: 16px !important;
}

.checkout-section-subheader {
  text-align: center;
  margin: 0 auto 8px auto;
  max-width: 375px;
  line-height: 1.5;
}

.checkout-payment-element-container {
  width: 100%;
  max-width: 415px;
  margin: 0 auto;
  margin-bottom: 16px;
}

.checkout-terms-and-conditions {
  font-size: 12px;
  line-height: 1.5;
  text-align: left;
  max-width: 400px;
  margin: 24px auto 16px auto;
  width: 100%;
}

.checkout-form-button {
  width: 128px;
}

.privacy-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: 8px;
  padding-right: 12px;
  text-align: right;
}

.privacy-footer a {
  font-size: 12px;
  color: #5f5f5f;
}